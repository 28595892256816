<template>
  <div class="Vehicle">
    <div class="title">请拍摄并上传您的有效身份证</div>

    <div class="upload-wrapper">
      <div class="item item-front" @click="chooseFileFront">
        <van-uploader ref="cardFront" :after-read="afterRead1">
          <div class="inner">
            <img v-if="!image1" src="../assets/auth_front.png" alt="" />
            <img v-else class="uploaded-img" :src="image1" alt="" />
            <p :style="{ background: '#' + $store.state.color }">
              身份证人像面
            </p>
          </div>
        </van-uploader>
      </div>
      <div class="item item-back" @click="chooseFileBack">
        <van-uploader ref="cardBack" :after-read="afterRead2">
          <div class="inner">
            <img v-if="!image2" src="../assets/auth_back.png" alt="" />
            <img v-else class="uploaded-img" :src="image2" alt="" />
            <p :style="{ background: '#' + $store.state.color }">
              身份证国徽面
            </p>
          </div>
        </van-uploader>
      </div>
    </div>

    <van-divider />

    <div class="upload-tips">
      <p><b>拍摄身份证要求：</b></p>
      <p>大陆公民持有的本人有效二代身份证；</p>
      <p>拍摄时确保身份证<span>边框完整，字体清晰，亮度均匀；</span></p>
      <ul>
        <li>
          <img src="../assets/auth_tips_01.png" alt="" />
          <span>标准</span>
        </li>
        <li>
          <img src="../assets/auth_tips_02.png" alt="" />
          <span>边框缺失</span>
        </li>
        <li>
          <img src="../assets/auth_tips_03.png" alt="" />
          <span>边框模糊</span>
        </li>
        <li>
          <img src="../assets/auth_tips_04.png" alt="" />
          <span>闪光强烈</span>
        </li>
      </ul>
    </div>

    <van-divider class="divider" />

    <div class="result-wrapper">
      <van-field v-model="form.name" label="姓名" placeholder="请输入" />
      <van-field
        v-model="form.idNo"
        label="身份证号"
        placeholder="请输入"
        readonly
      />
      <van-field
        v-model="form.registerAddress"
        label="户籍"
        placeholder="请输入"
      />
      <van-field
        v-model="form.bankCode"
        label="银行卡号"
        placeholder="请输入"
        @blur="getBankName"
      />
      <van-field v-model="form.bank" label="所属银行" placeholder="请输入" />
      <van-field v-model="form.phone" label="手机号码" placeholder="请输入" />
      <van-field
        v-model="form.smsCode"
        center
        clearable
        label="短信验证码"
        maxlength="4"
        placeholder="请输入"
      >
        <template #button>
          <van-button
            v-if="!counting"
            class="btn-send"
            size="small"
            type="info"
            plain
            :disabled="form.phone.length < 11"
            @click="getCode"
            >获取验证码</van-button
          >
          <span v-if="counting" class="count-down">{{ seconds }}s后可重发</span>
        </template>
      </van-field>

      <van-field
        v-model="educationBackground"
        is-link
        readonly
        label="学历"
        placeholder="请选择"
        @click="eduShow = true"
      />
      <van-field v-model="form.emergencyContact" placeholder="请输入">
        <template #label>
          <div>
            紧急联系人<br />
            姓名
          </div>
        </template>
      </van-field>
      <van-field
        v-model="emergencyContactRela"
        is-link
        readonly
        label="与本人关系"
        placeholder="请选择"
        @click="emergencyShow = true"
      />
      <van-field v-model="form.emergencyContactPhone" placeholder="请输入">
        <template #label>
          <div>
            紧急联系人<br />
            电话
          </div>
        </template>
      </van-field>
      <van-field
        v-model="form.workPost"
        is-link
        readonly
        label="岗位"
        placeholder="请选择"
        @click="workShow = true"
      />
      <van-field v-if="workPost == 1" label="特殊作业证">
        <template #input>
          <div class="item-image" @click="chooseFile('spPostFile')">
            <van-uploader
              ref="spPostFile"
              :after-read="(file) => afterRead(file, 'spPostFile')"
            >
              <div class="inner">
                <van-icon v-if="!spPostFile" name="photograph" />
                <img v-else class="uploaded-img" :src="spPostFile" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field v-if="showVehicleFlag" label="是否使用载具" name="switch">
        <template #input>
          <van-switch
            v-model="form.vehicleFlag"
            :active-value="1"
            :inactive-value="0"
            size="20"
            disabled
          />
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag"
        v-model="vehicleType"
        is-link
        readonly
        label="载具类型"
        placeholder="请选择"
        @click="vehicleTypeShow = true"
      />
      <van-field name="switch" v-if="form.vehicleFlag && form.vehicleType == 1">
        <template #label>
          <div>
            在当地是否<br />
            视为机动车
          </div>
        </template>
        <template #input>
          <van-switch
            v-model="form.motorFlag"
            :active-value="1"
            :inactive-value="0"
            size="20"
          />
        </template>
      </van-field>
      <!-- :required="form.vehicleType == 2 ? true : false" -->
      <van-field v-if="form.vehicleFlag && form.motorFlag" label="驾驶证">
        <template #input>
          <div class="item-image" @click="chooseFile('driverLicense')">
            <van-uploader
              ref="driverLicense"
              :after-read="(file) => afterRead(file, 'driverLicense')"
            >
              <div class="inner">
                <van-icon v-if="!driverLicense" name="photograph" />
                <img v-else class="uploaded-img" :src="driverLicense" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.motorFlag"
        v-model="form.licensePlate"
        placeholder="请输入"
      >
        <template #label>
          <div>
            机动车车辆<br />
            牌照号
          </div>
        </template>
      </van-field>
      <van-field v-if="form.vehicleFlag" label="载具照片">
        <template #input>
          <div class="item-image" @click="chooseFile('carUserPic')">
            <van-uploader
              ref="carUserPic"
              :after-read="(file) => afterRead(file, 'carUserPic')"
            >
              <div class="inner">
                <van-icon v-if="!carUserPic" name="photograph" />
                <img v-else class="uploaded-img" :src="carUserPic" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        label="交强险保单"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('compInsurance')">
            <van-uploader
              ref="compInsurance"
              :after-read="(file) => afterRead(file, 'compInsurance')"
            >
              <div class="inner">
                <van-icon v-if="!compInsurance" name="photograph" />
                <img v-else class="uploaded-img" :src="compInsurance" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        label="交强险保单号"
        v-model="form.compInsuranceNo"
        placeholder="请输入"
      />
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        v-model="form.compInsuranceEndDate"
        is-link
        readonly
        placeholder="请选择"
        @click="
          datetimeVisible = true;
          current = 'compInsuranceEndDate';
        "
      >
        <template #label>
          <div>
            交强险<br />
            有效期至
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        label="商业险保单"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('commInsurance')">
            <van-uploader
              ref="commInsurance"
              :after-read="(file) => afterRead(file, 'commInsurance')"
            >
              <div class="inner">
                <van-icon v-if="!commInsurance" name="photograph" />
                <img v-else class="uploaded-img" :src="commInsurance" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        v-model="form.commInsuranceNo"
        placeholder="请输入"
      >
        <template #label>
          <div>
            商业险保单<br />
            号码
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        v-model="form.commInsuranceThreeAmount"
        placeholder="请输入"
      >
        <template #label>
          <div>
            商业险三者<br />
            责任险保额
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        v-model="form.commInsuranceEndDate"
        is-link
        readonly
        placeholder="请选择"
        @click="
          datetimeVisible = true;
          current = 'commInsuranceEndDate';
        "
      >
        <template #label>
          <div>
            商业险<br />
            有效期至
          </div>
        </template>
      </van-field>

      <div class="tips">
        <span @click="showAgreement('privacy')">《平台隐私政策协议》</span>
        <span @click="showAgreement('register')">《平台注册服务协议》</span>
      </div>
      <button
        v-if="!isSubmiting"
        class="btn"
        :disabled="isDisabled"
        :style="{ background: '#' + $store.state.color }"
        @click="handleSubmit"
      >
        身份认证
      </button>
      <button v-else class="btn" disabled>提交中</button>
    </div>

    <!-- {{ form }} -->

    <van-dialog
      v-model="agreementVisible"
      confirmButtonText="我已阅读并同意以上条款"
      width="3.6rem"
    >
      <Agreement :type="agreementType" :agreementVisible="agreementVisible" />
    </van-dialog>

    <van-popup v-model="eduShow" round position="bottom">
      <van-picker
        title="学历"
        show-toolbar
        :columns="eduColumns"
        @confirm="onEduConfirm"
      />
    </van-popup>

    <van-popup v-model="emergencyShow" round position="bottom">
      <van-picker
        title="与本人关系"
        show-toolbar
        :columns="emergencyColumns"
        @confirm="onEmergencyConfirm"
      />
    </van-popup>

    <van-popup v-model="vehicleTypeShow" round position="bottom">
      <van-picker
        title="载具类型"
        show-toolbar
        :columns="vehicleTypeColumns"
        @confirm="onVehicleTypeConfirm"
      />
    </van-popup>

    <van-popup v-model="datetimeVisible" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="datetimeConfirm"
        @cancel="datetimeCancel"
      />
    </van-popup>

    <van-popup v-model="workShow" round position="bottom">
      <van-picker
        title="岗位"
        show-toolbar
        :columns="workColumns"
        @confirm="onPostConfirm"
      />
    </van-popup>

    <BackgroundCheck />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
// import axios from '@/utils/request.js';
import {
  ActionSheet,
  Button,
  Dialog,
  Divider,
  Field,
  Icon,
  Notify,
  Picker,
  Popup,
  Switch,
  Toast,
  Uploader,
} from 'vant';
import Agreement from '../components/Agreement.vue';
import BackgroundCheck from '../components/BackgroundCheck.vue';
import OSS from 'ali-oss';
import { getOssToken, getOssFile } from '../utils/getOss';
import Compressor from 'compressorjs';

Vue.use(ActionSheet)
  .use(Button)
  .use(Dialog)
  .use(Divider)
  .use(Field)
  .use(Icon)
  .use(Notify)
  .use(Picker)
  .use(Popup)
  .use(Switch)
  .use(Uploader);

export default {
  name: 'Sg',
  components: {
    Agreement,
    BackgroundCheck,
  },
  data() {
    return {
      backgroundCheckVisible: true,
      showVehicleFlag: false,
      positionNo: '',
      image1: null,
      image2: null,
      successFlag1: false,
      successFlag2: false,
      form: {
        idCardFront: '',
        idCardReverse: '',
        name: '',
        idNo: '',
        registerAddress: '',
        bank: '',
        bankCode: '',
        phone: '',
        smsCode: '',
        issuingAuthority: '',
        issuingDate: '',
        loseDate: '',

        educationBackground: '',
        emergencyContactRela: '',
        emergencyContact: '',
        emergencyContactPhone: '',
        vehicleFlag: 0,
        motorFlag: 0,
        vehicleType: '',
        licensePlate: '',
        driverLicense: '',
        carUserPic: '',
        compInsurance: '',
        compInsuranceNo: '',
        compInsuranceEndDate: '',
        commInsurance: '',
        commInsuranceNo: '',
        commInsuranceThreeAmount: '',
        commInsuranceEndDate: '',
        compInsuranceNoOcr: '',
        compInsuranceEndDateOcr: '',
        commInsuranceNoOcr: '',
        commInsuranceEndDateOcr: '',
        commInsuranceThreeAmountOcr: '',
        workPost: '',
        spPostFile: '',
      },
      educationBackground: '',
      eduShow: false,
      eduColumns: [],
      emergencyContactRela: '',
      emergencyShow: false,
      emergencyColumns: [],
      vehicleType: '',
      vehicleTypeShow: false,
      vehicleTypeColumns: [],
      seconds: 60,
      counting: false,
      uploading: null,
      agreementVisible: true,
      isSubmiting: false,
      clientOSS: {},
      randomString: '',
      driverLicense: '',
      carUserPic: '',
      compInsurance: '',
      commInsurance: '',
      agreementType: 'privacy',
      minDate: new Date(),
      maxDate: new Date(2050, 11, 31),
      currentDate: new Date(),
      datetimeVisible: false,
      spPostFile: '',
      workPost: '',
      workShow: false,
      workColumns: [],
    };
  },
  computed: {
    isDisabled() {
      return (
        !this.form.name ||
        !this.form.idNo ||
        !this.form.registerAddress ||
        !this.form.bank ||
        !this.form.bankCode ||
        !this.form.phone ||
        !this.form.smsCode ||
        !this.image1 ||
        !this.image2 ||
        !this.successFlag1 ||
        !this.successFlag2 ||
        this.form.educationBackground === '' ||
        !this.form.emergencyContact ||
        !this.form.emergencyContactRela ||
        !this.form.emergencyContactPhone ||
        (this.form.vehicleFlag == 1 && !this.form.vehicleType) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.licensePlate) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.driverLicense) ||
        (this.form.vehicleFlag == 1 && !this.form.carUserPic) ||
        // (this.form.vehicleFlag == 1 &&
        //   this.form.motorFlag &&
        //   !this.form.compInsurance) ||
        // (this.form.vehicleFlag == 1 &&
        //   this.form.motorFlag &&
        //   !this.form.compInsuranceNo) ||
        // (this.form.vehicleFlag == 1 &&
        //   this.form.motorFlag &&
        //   !this.form.compInsuranceEndDate) ||

        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.commInsurance) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.commInsuranceNo) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.commInsuranceThreeAmount) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.commInsuranceEndDate) ||
        !this.form.workPost ||
        (this.workPost == 1 && !this.form.spPostFile)
      );
    },
    ...mapState({
      taskId: (state) => state.taskId,
      color: (state) => state.color,
    }),
  },
  created() {
    // let positionNo = this.$route.query.taskNum;
    // this.positionNo = positionNo;

    this.getToken();

    this.randomString = this.getRandomString(10);

    this.getList('sg_educationBackground', 'eduColumns');
    this.getList('sg_emergencyContactRela', 'emergencyColumns');
    this.getList('sg_vehicleType', 'vehicleTypeColumns');
    this.getPostList();
  },
  methods: {
    async getList(key, list) {
      const data = await this.$axios.post(
        '/system/dictController/queryDictByKey',
        {
          key,
        }
      );
      if (data.success) {
        for (const item of data.data) {
          item.text = item.dictName;
          item.value = item.dictId;
        }
        this[list] = data.data;
      }
    },
    async getPostList() {
      const data = await this.$axios.post('/tax/wechat/rosterApi/sgPost');
      if (data.success) {
        for (const item of data.data) {
          item.text = item.postName;
          item.value = item.id;
        }
        this.workColumns = data.data;
      }
    },
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
    onEduConfirm(obj) {
      const { text, value } = obj;
      this.form.educationBackground = value;
      this.educationBackground = text;
      this.eduShow = false;
    },
    onEmergencyConfirm(obj) {
      const { text, value } = obj;
      this.form.emergencyContactRela = value;
      this.emergencyContactRela = text;
      this.emergencyShow = false;
    },
    onVehicleTypeConfirm(obj) {
      const { text, value } = obj;
      this.form.vehicleType = value;
      this.vehicleType = text;
      this.vehicleTypeShow = false;
      this.form.motorFlag = this.form.vehicleType == 2 ? 1 : 0;
    },
    onPostConfirm(obj) {
      const { text, spPost, vehicleFlag } = obj;
      this.form.workPost = text;
      this.workPost = spPost;
      this.workShow = false;
      this.showVehicleFlag = vehicleFlag;
      this.form.vehicleFlag = vehicleFlag;
      this.form.motorFlag = 0;
    },
    chooseFileFront() {
      this.$refs.cardFront.chooseFile();
    },
    chooseFileBack() {
      this.$refs.cardBack.chooseFile();
    },
    async afterRead1(file) {
      let image = file.file;
      // console.log('before', image.size);
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
        // console.log('after', image.size);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/cert/${date}/${t}-${this.randomString}-01${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        let url = await getOssFile(path);
        // console.log(res);
        this.image1 = url;
        this.form.idCardFront = `/${path}`;
        this.handleOcr(url, 'front');
      } else {
        this.uploading.clear();
      }
    },
    async afterRead2(file) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/cert/${date}/${t}-${this.randomString}-02${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        let url = await getOssFile(path);
        this.image2 = url;
        this.form.idCardReverse = `/${path}`;
        // this.handleOcr(url, 'back');
        this.successFlag2 = true;
      } else {
        this.successFlag2 = false;
      }
      this.uploading.clear();
    },
    async handleOcr(url, side) {
      const data = await this.$axios.post('/tool/baidu/urlIdNoOcr', {
        url,
        side,
        flag: 1,
      });
      if (data.success) {
        if (side == 'front') {
          let {
            姓名: name,
            公民身份号码: idno,
            住址: registerAddress,
          } = data.data.words_result;
          name && (this.form.name = name.words);
          idno && (this.form.idNo = idno.words);
          registerAddress &&
            (this.form.registerAddress = registerAddress.words);
          this.successFlag1 = true;
        } else {
          let {
            签发机关: issuingAuthority,
            签发日期: issuingDate,
            失效日期: loseDate,
          } = data.data.words_result;
          // if (!issuingAuthority || !issuingDate || !loseDate) {
          //   this.successFlag2 = false;
          //   Toast({
          //     message: '请上传正确的身份证图片',
          //     position: 'bottom',
          //   });
          //   return;
          // }
          issuingAuthority &&
            (this.form.issuingAuthority = issuingAuthority.words);
          issuingDate && (this.form.issuingDate = issuingDate.words);
          loseDate &&
            (this.form.loseDate =
              loseDate == '长期--' ? '9999-12-30' : loseDate.words);
          this.successFlag2 = true;
        }
      } else {
        side == 'front'
          ? (this.successFlag1 = false)
          : (this.successFlag2 = false);
      }
      this.uploading.clear();
    },
    async handleOcrInsurance(selectType, url) {
      const data = await this.$axios.post('/tool/baidu/bdOcr', {
        url,
      });
      if (data.success) {
        const { validityTime, insuranceNo, threeAmount, type } = data.data;
        // type 1交强险 2商业险
        if (
          (selectType == 'compInsurance' && type != 1) ||
          (selectType == 'commInsurance' && type != 2)
        ) {
          Toast({
            message: '请上传正确的保单图片',
            position: 'bottom',
          });
          return;
        }
        let d = validityTime
          ? this.$dayjs(validityTime, 'YYYY年MM月DD日').format('YYYY-MM-DD')
          : '';
        if (selectType == 'compInsurance') {
          this.form.compInsuranceNoOcr = insuranceNo;
          this.form.compInsuranceEndDateOcr = d;
          this.form.compInsuranceNo = insuranceNo;
          this.form.compInsuranceEndDate = d;
        } else {
          this.form.commInsuranceNoOcr = insuranceNo;
          this.form.commInsuranceEndDateOcr = d;
          this.form.commInsuranceThreeAmountOcr = threeAmount;
          this.form.commInsuranceNo = insuranceNo;
          this.form.commInsuranceEndDate = d;
          this.form.commInsuranceThreeAmount = threeAmount;
        }
      }
    },
    async getCode() {
      let reg = /^1\d{10}$/;
      if (!reg.test(this.form.phone)) {
        Toast({
          message: '请输入正确格式的手机号码',
          position: 'bottom',
        });
        return;
      }
      this.counting = true;
      this.seconds = 60;
      let t1 = setInterval(() => {
        if (this.seconds < 1) {
          this.counting = false;
          clearInterval(t1);
        } else {
          this.seconds--;
        }
      }, 1000);
      const data = await this.$axios.post('/system/wechatController/sendMsg', {
        positionId: this.taskId,
        phone: this.form.phone,
        type: 3,
      });
      if (data.success) {
      }
    },
    handleSubmit() {
      if (this.form.motorFlag) {
        Dialog.confirm({
          confirmButtonText: '是',
          cancelButtonText: '否',
          title: '90天内是否能承诺完成考证？',
          message: '提醒：未按约定时间取证者，公司有权单方面解除承揽服务协议。',
        })
          .then(() => {
            this.addUser();
          })
          .catch(() => {});
      } else {
        this.addUser();
      }
    },
    async addUser() {
      this.isSubmiting = true;
      let postData = { ...this.form };
      postData.positionId = this.taskId;
      if (!this.form.vehicleFlag) {
        delete postData.vehicleType;
        delete postData.motorFlag;
        delete postData.driverLicense;
        delete postData.licensePlate;
        delete postData.carUserPic;
        delete postData.compInsurance;
        delete postData.compInsuranceNo;
        delete postData.compInsuranceEndDate;
        delete postData.commInsurance;
        delete postData.commInsuranceNo;
        delete postData.commInsuranceThreeAmount;
        delete postData.commInsuranceEndDate;
      }
      const data = await this.$axios.post(
        '/tax/wechat/user/sgAddUserInfoSg',
        postData
      );
      if (data.success) {
        this.getUrl(data.data);
        this.uploading = Toast.loading({
          message: '合同生成中',
          forbidClick: true,
        });
      } else {
        this.isSubmiting = false;
      }
    },
    async getUrl(accountId) {
      const data = await this.$axios.post('/tax/wechatController/doEleSign', {
        accountId,
        taskId: this.taskId,
      });
      if (data.success) {
        if (data.data.shortUrl) {
          window.location.href = data.data.shortUrl;
        } else {
          this.uploading.clear();
          this.$router.replace('result');
        }
      }
      this.isSubmiting = false;
    },
    getBankName() {
      if (!this.form.bankCode) {
        return;
      }
      this.$axios
        .post('/system/systemFileController/getBankName', {
          cardNo: this.form.bankCode.trim(),
        })
        .then((data) => {
          if (data.success) {
            this.form.bank = data.data ? data.data.split('·')[0] : '';
          }
        })
        .catch((error) => {
          console.log(error);
          this.form.bank = '';
        });
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    chooseFile(type) {
      this.$refs[type].chooseFile();
    },
    async afterRead(file, current) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/songguo/${date}/${t}-${this.randomString}-${current}${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        let url = await getOssFile(path);
        this[current] = url;
        this.form[current] = `/${path}`;

        // 保单ocr
        switch (current) {
          case 'compInsurance':
            this.handleOcrInsurance('compInsurance', `/${path}`);
            break;
          case 'commInsurance':
            this.handleOcrInsurance('commInsurance', `/${path}`);
            break;
          default:
            break;
        }
      }
      this.uploading.clear();
    },
    showAgreement(type) {
      this.agreementType = type;
      this.agreementVisible = true;
    },
    datetimeConfirm() {
      this.form[this.current] = this.$dayjs(this.currentDate).format(
        'YYYY-MM-DD'
      );
      this.datetimeCancel();
    },
    datetimeCancel() {
      this.current = '';
      this.currentDate = new Date();
      this.datetimeVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/vars';

.Vehicle {
  min-height: 100vh;
  background: #fff;
  padding-bottom: 0.58rem;
  box-sizing: border-box;

  .title {
    padding: 0.1rem 0 0.16rem;
    color: #999;
    font-size: 0.13rem;
    text-align: center;
  }

  .upload-wrapper {
    padding: 0 0.2rem;
    text-align: center;

    .item {
      position: relative;
      z-index: 10;
      display: inline-block;
      width: 1.8rem;
      height: 1.62rem;
      background: #f4f8fe;
      border-radius: 0.1rem;
      vertical-align: top;

      .van-uploader {
        z-index: -10;
        pointer-events: none;
      }

      &.item-front {
        margin-right: 0.07rem;
      }

      &.item-back {
        margin-left: 0.07rem;
      }

      .inner {
        img {
          display: inline-block;
          width: 1.5rem;
          height: 1rem;
          margin: 0.15rem;
          vertical-align: top;
        }

        p {
          height: 0.32rem;
          line-height: 0.32rem;
          background: $blue;
          border-radius: 0 0 0.1rem 0.1rem;
          color: #fff;
          text-align: center;

          @at-root .theme-orange & {
            background: $orange;
          }
        }
      }
    }
  }

  .upload-tips {
    margin: 0.2rem;

    p {
      b {
        display: block;
        margin-bottom: 0.1rem;
      }

      span {
        color: #ff5050;
      }
    }

    ul {
      display: flex;
      margin-top: 0.24rem;

      li {
        flex: 1;
        text-align: center;

        &:not(:last-child) {
          padding-right: 0.05rem;
        }

        img {
          width: 100%;
        }

        span {
          color: #919191;
          font-size: 0.12rem;
        }
      }
    }
  }

  .divider {
    margin: 0;
  }

  .result-wrapper {
    .btn-send {
      @at-root .theme-orange & {
        color: $orange;
        border-color: $orange;
      }

      &:disabled {
        color: #e1e4ea;
        border-color: #e1e4ea;
        opacity: 1;
      }
    }

    .btn {
      display: block;
      width: 3.8rem;
      height: 0.44rem;
      background-color: $blue;
      margin: 0.46rem auto 0;
      padding: 0;
      border: none;
      border-radius: 0.22rem;
      color: #fff;
      font-size: 0.17rem;
      // box-shadow: 0 0.03rem 0.1rem rgba($blue, 0.3);
      box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);

      @at-root .theme-orange & {
        background: $orange;
        border-color: $orange;
        // box-shadow: 0 0.03rem 0.1rem rgba($orange, 0.3);
      }

      &:disabled {
        background-color: $gray !important;
        // box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);
      }
    }
  }

  .tips {
    margin: 0.2rem 0;
    text-align: center;

    span {
      color: $blue;

      @at-root .theme-orange & {
        color: $orange;
      }
    }
  }

  .item-image {
    position: relative;
    z-index: 10;
    width: 0.8rem;
    height: 0.8rem;

    .van-uploader {
      z-index: -10;
      pointer-events: none;
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 0.8rem;
      height: 0.8rem;
      background-color: #f7f8fa;

      img {
        width: 100%;
        height: 100%;
      }

      .van-icon {
        color: #dcdee0;
        font-size: 0.24rem;
      }
    }
  }
}
</style>
