import Vue from 'vue';
import Router from 'vue-router';
import Standard from '../pages/Standard.vue';
import Simple from '../pages/Simple.vue';
import Result from '../pages/Result.vue';
import Vehicle from '../pages/Vehicle.vue';
import Download from '../pages/Download.vue';
import Resign from '../pages/Resign.vue';
import Three from '../pages/Three.vue';
import Irregular from '../pages/Irregular.vue';
import Mt from '../pages/Mt.vue';
import Didi from '../pages/Didi.vue';
import ClockinResult from '../pages/ClockinResult.vue';
import Additional from '../pages/Additional.vue';
import Sg from '../pages/Sg.vue';
import SgUpdate from '../pages/SgUpdate.vue';
import Sunshine from '../pages/Sunshine.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'standard',
      component: Standard,
    },
    {
      path: '/additional',
      name: 'additional',
      component: Additional,
    },
    {
      path: '/simple',
      name: 'simple',
      component: Simple,
    },
    {
      path: '/result',
      name: 'result',
      component: Result,
    },
    {
      path: '/vehicle',
      name: 'vehicle',
      component: Vehicle,
    },
    {
      path: '/d',
      name: 'download',
      component: Download,
    },
    {
      path: '/resign',
      name: 'resign',
      component: Resign,
    },
    {
      path: '/three',
      name: 'three',
      component: Three,
    },
    {
      path: '/irregular',
      name: 'irregular',
      component: Irregular,
    },
    {
      path: '/mt',
      name: 'mt',
      component: Mt,
    },
    {
      path: '/didi',
      name: 'didi',
      component: Didi,
    },
    {
      path: '/clockinResult',
      name: 'clockinResult',
      component: ClockinResult,
    },
    {
      path: '/sg',
      name: 'sg',
      component: Sg,
    },
    {
      path: '/sgUpdate',
      name: 'sgUpdate',
      component: SgUpdate,
    },
    {
      path: '/sunshine',
      name: 'sunshine',
      component: Sunshine,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

router.afterEach((to, from) => {
  let bodySrcollTop = document.body.scrollTop;
  if (bodySrcollTop !== 0) {
    document.body.scrollTop = 0;
    return;
  }
  let docSrcollTop = document.documentElement.scrollTop;
  if (docSrcollTop !== 0) {
    document.documentElement.scrollTop = 0;
  }
});

export default router;
