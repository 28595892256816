import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    // userInfo: {},
    platformId: null, //1久来米 2众城
    color: '',
    taskId: null,
    platform: '',
    bgCheckVisible: 0,
  },
  mutations: {
    // setUserInfo(state, payload) {
    //   state.userInfo = payload.userInfo;
    //   console.log('state.userinfo', state.userInfo);
    // },
    setPlatformId(state, id) {
      state.platformId = id;
    },
    setColor(state, v) {
      state.color = v;
    },
    setTaskId(state, id) {
      state.taskId = id;
    },
    setPlatform(state, v) {
      state.platform = v;
    },
    setBgCheckVisible(state, current) {
      state.bgCheckVisible = current;
    },
  },
});

export default store;
