<template>
  <div class="Vehicle">
    <div class="result-wrapper">
      <van-field v-model="form.name" label="姓名" readonly />
      <van-field v-model="form.idNo" label="身份证号" readonly />
      <van-field v-model="form.workPost" readonly label="岗位" />
      <van-field v-if="form.spPost == 1" label="特殊作业证">
        <template #input>
          <div class="item-image" @click="chooseFile('spPostFile')">
            <van-uploader
              ref="spPostFile"
              :after-read="(file) => afterRead(file, 'spPostFile')"
            >
              <div class="inner">
                <van-icon v-if="!spPostFile" name="photograph" />
                <img v-else class="uploaded-img" :src="spPostFile" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field v-if="showVehicleFlag" label="是否使用载具" name="switch">
        <template #input>
          <van-switch
            v-model="form.vehicleFlag"
            :active-value="1"
            :inactive-value="0"
            size="20"
            disabled
          />
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag"
        v-model="vehicleType"
        is-link
        readonly
        label="载具类型"
        placeholder="请选择"
        @click="vehicleTypeShow = true"
      />
      <van-field name="switch" v-if="form.vehicleFlag && form.vehicleType == 1">
        <template #label>
          <div>
            在当地是否<br />
            视为机动车
          </div>
        </template>
        <template #input>
          <van-switch
            v-model="form.motorFlag"
            :active-value="1"
            :inactive-value="0"
            size="20"
          />
        </template>
      </van-field>
      <!-- :required="form.vehicleType == 2 ? true : false" -->
      <van-field v-if="form.vehicleFlag && form.motorFlag" label="驾驶证">
        <template #input>
          <div class="item-image" @click="chooseFile('driverLicense')">
            <van-uploader
              ref="driverLicense"
              :after-read="(file) => afterRead(file, 'driverLicense')"
            >
              <div class="inner">
                <van-icon v-if="!driverLicense" name="photograph" />
                <img v-else class="uploaded-img" :src="driverLicense" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.motorFlag"
        v-model="form.licensePlate"
        placeholder="请输入"
      >
        <template #label>
          <div>
            机动车车辆<br />
            牌照号
          </div>
        </template>
      </van-field>
      <van-field v-if="form.vehicleFlag" label="载具照片">
        <template #input>
          <div class="item-image" @click="chooseFile('carUserPic')">
            <van-uploader
              ref="carUserPic"
              :after-read="(file) => afterRead(file, 'carUserPic')"
            >
              <div class="inner">
                <van-icon v-if="!carUserPic" name="photograph" />
                <img v-else class="uploaded-img" :src="carUserPic" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        label="交强险保单"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('compInsurance')">
            <van-uploader
              ref="compInsurance"
              :after-read="(file) => afterRead(file, 'compInsurance')"
            >
              <div class="inner">
                <van-icon v-if="!compInsurance" name="photograph" />
                <img v-else class="uploaded-img" :src="compInsurance" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        label="交强险保单号"
        v-model="form.compInsuranceNo"
        placeholder="请输入"
      />
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        v-model="form.compInsuranceEndDate"
        is-link
        readonly
        placeholder="请选择"
        @click="
          datetimeVisible = true;
          current = 'compInsuranceEndDate';
        "
      >
        <template #label>
          <div>
            交强险<br />
            有效期至
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        label="商业险保单"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('commInsurance')">
            <van-uploader
              ref="commInsurance"
              :after-read="(file) => afterRead(file, 'commInsurance')"
            >
              <div class="inner">
                <van-icon v-if="!commInsurance" name="photograph" />
                <img v-else class="uploaded-img" :src="commInsurance" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        v-model="form.commInsuranceNo"
        placeholder="请输入"
      >
        <template #label>
          <div>
            商业险保单<br />
            号码
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        v-model="form.commInsuranceThreeAmount"
        placeholder="请输入"
      >
        <template #label>
          <div>
            商业险三者<br />
            责任险保额
          </div>
        </template>
      </van-field>
      <van-field
        v-if="form.vehicleFlag && form.vehicleType == 2"
        v-model="form.commInsuranceEndDate"
        is-link
        readonly
        placeholder="请选择"
        @click="
          datetimeVisible = true;
          current = 'commInsuranceEndDate';
        "
      >
        <template #label>
          <div>
            商业险<br />
            有效期至
          </div>
        </template>
      </van-field>

      <div class="tips">
        <span @click="showAgreement('privacy')">《平台隐私政策协议》</span>
        <span @click="showAgreement('register')">《平台注册服务协议》</span>
      </div>
      <button
        v-if="!isSubmiting"
        class="btn"
        :disabled="isDisabled"
        :style="{ background: '#' + $store.state.color }"
        @click="handleSubmit"
      >
        提交
      </button>
      <button v-else class="btn" disabled>提交中</button>
    </div>

    <!-- {{ form }} -->

    <van-dialog
      v-model="agreementVisible"
      confirmButtonText="我已阅读并同意以上条款"
      width="3.6rem"
    >
      <Agreement :type="agreementType" :agreementVisible="agreementVisible" />
    </van-dialog>

    <van-popup v-model="vehicleTypeShow" round position="bottom">
      <van-picker
        title="载具类型"
        show-toolbar
        :columns="vehicleTypeColumns"
        @confirm="onVehicleTypeConfirm"
      />
    </van-popup>

    <van-popup v-model="datetimeVisible" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="datetimeConfirm"
        @cancel="datetimeCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
// import axios from '@/utils/request.js';
import {
  ActionSheet,
  Button,
  Dialog,
  Divider,
  Field,
  Icon,
  Notify,
  Picker,
  Popup,
  Switch,
  Toast,
  Uploader,
} from 'vant';
import Agreement from '../components/Agreement.vue';
import OSS from 'ali-oss';
import { getOssToken, getOssFile } from '../utils/getOss';
import Compressor from 'compressorjs';

Vue.use(ActionSheet)
  .use(Button)
  .use(Dialog)
  .use(Divider)
  .use(Field)
  .use(Icon)
  .use(Notify)
  .use(Picker)
  .use(Popup)
  .use(Switch)
  .use(Uploader);

export default {
  name: 'SgUpdate',
  components: {
    Agreement,
  },
  data() {
    return {
      code: '',
      showVehicleFlag: false,
      form: {
        name: '',
        idNo: '',
        vehicleFlag: 0,
        motorFlag: 0,
        vehicleType: '',
        licensePlate: '',
        driverLicense: '',
        carUserPic: '',
        compInsurance: '',
        compInsuranceNo: '',
        compInsuranceEndDate: '',
        commInsurance: '',
        commInsuranceNo: '',
        commInsuranceThreeAmount: '',
        commInsuranceEndDate: '',
        compInsuranceNoOcr: '',
        compInsuranceEndDateOcr: '',
        commInsuranceNoOcr: '',
        commInsuranceEndDateOcr: '',
        commInsuranceThreeAmountOcr: '',
        workPost: '',
        spPostFile: '',
      },
      vehicleType: '',
      vehicleTypeShow: false,
      vehicleTypeColumns: [],
      seconds: 60,
      counting: false,
      uploading: null,
      agreementVisible: true,
      isSubmiting: false,
      clientOSS: {},
      randomString: '',
      driverLicense: '',
      carUserPic: '',
      compInsurance: '',
      commInsurance: '',
      agreementType: 'privacy',
      minDate: new Date(),
      maxDate: new Date(2050, 11, 31),
      currentDate: new Date(),
      datetimeVisible: false,
      spPostFile: '',
      workShow: false,
      workColumns: [],
    };
  },
  computed: {
    isDisabled() {
      return (
        (this.form.vehicleFlag == 1 && !this.form.vehicleType) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.licensePlate) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.driverLicense) ||
        (this.form.vehicleFlag == 1 && !this.form.carUserPic) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.commInsurance) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.commInsuranceNo) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.commInsuranceThreeAmount) ||
        (this.form.vehicleFlag == 1 &&
          this.form.vehicleType == 2 &&
          !this.form.commInsuranceEndDate) ||
        (this.form.spPost == 1 && !this.form.spPostFile)
      );
    },
    ...mapState({
      taskId: (state) => state.taskId,
      color: (state) => state.color,
    }),
  },
  created() {
    const { code } = this.$route.query;
    this.code = code;
    this.getInfo(code);

    this.getToken();

    this.randomString = this.getRandomString(10);

    this.getList('sg_vehicleType', 'vehicleTypeColumns');
    this.getPostList();
  },
  methods: {
    async getInfo(code) {
      const data = await this.$axios.post('/tax/wechat/rosterApi/getCollInfo', {
        code,
      });
      if (data.success) {
        const {
          collAgain,
          spPostFile,
          driverLicense,
          carUserPic,
          compInsurance,
          commInsurance,
          backPost: workPost,
        } = data.data;
        this.form = { ...data.data, workPost };
        if (collAgain) {
          Toast({
            message: '已提交过信息，需要变更请联系运维人员',
            duration: 0,
            overlay: true,
            forbidClick: true,
          });
          return;
        }
        let arr = Array.from([
            spPostFile,
            driverLicense,
            carUserPic,
            compInsurance,
            commInsurance,
          ]),
          url = [];
        for (const item of arr) {
          if (item) {
            let res = await getOssFile(item);
            url.push(res);
          } else {
            url.push('');
          }
        }
        this.spPostFile = url[0];
        this.driverLicense = url[1];
        this.carUserPic = url[2];
        this.compInsurance = url[3];
        this.commInsurance = url[4];
      }
    },
    async getList(key, list) {
      const data = await this.$axios.post(
        '/system/dictController/queryDictByKey',
        {
          key,
        }
      );
      if (data.success) {
        for (const item of data.data) {
          item.text = item.dictName;
          item.value = item.dictId;
        }
        this[list] = data.data;
      }
    },
    async getPostList() {
      const data = await this.$axios.post('/tax/wechat/rosterApi/sgPost');
      if (data.success) {
        for (const item of data.data) {
          item.text = item.postName;
          item.value = item.id;
        }
        this.workColumns = data.data;
      }
    },
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
    onVehicleTypeConfirm(obj) {
      const { text, value } = obj;
      this.form.vehicleType = value;
      this.vehicleType = text;
      this.vehicleTypeShow = false;
      this.form.motorFlag = this.form.vehicleType == 2 ? 1 : 0;
    },
    async handleOcrInsurance(selectType, url) {
      const data = await this.$axios.post('/tool/baidu/bdOcr', {
        url,
      });
      if (data.success) {
        const { validityTime, insuranceNo, threeAmount, type } = data.data;
        // type 1交强险 2商业险
        if (
          (selectType == 'compInsurance' && type != 1) ||
          (selectType == 'commInsurance' && type != 2)
        ) {
          Toast({
            message: '请上传正确的保单图片',
            position: 'bottom',
          });
          return;
        }
        let d = validityTime
          ? this.$dayjs(validityTime, 'YYYY年MM月DD日').format('YYYY-MM-DD')
          : '';
        if (selectType == 'compInsurance') {
          this.form.compInsuranceNoOcr = insuranceNo;
          this.form.compInsuranceEndDateOcr = d;
          this.form.compInsuranceNo = insuranceNo;
          this.form.compInsuranceEndDate = d;
        } else {
          this.form.commInsuranceNoOcr = insuranceNo;
          this.form.commInsuranceEndDateOcr = d;
          this.form.commInsuranceThreeAmountOcr = threeAmount;
          this.form.commInsuranceNo = insuranceNo;
          this.form.commInsuranceEndDate = d;
          this.form.commInsuranceThreeAmount = threeAmount;
        }
      }
    },
    handleSubmit() {
      this.isSubmiting = true;
      let postData = { ...this.form, code: this.code };
      // if (!this.form.vehicleFlag) {
      //   delete postData.vehicleType;
      //   delete postData.motorFlag;
      //   delete postData.driverLicense;
      //   delete postData.licensePlate;
      //   delete postData.carUserPic;
      //   delete postData.compInsurance;
      //   delete postData.compInsuranceNo;
      //   delete postData.compInsuranceEndDate;
      //   delete postData.commInsurance;
      //   delete postData.commInsuranceNo;
      //   delete postData.commInsuranceThreeAmount;
      //   delete postData.commInsuranceEndDate;
      // }
      this.addUser(postData);
    },
    async addUser(postData) {
      const data = await this.$axios.post(
        '/tax/wechat/rosterApi/updateCollInfo',
        postData
      );
      if (data.success) {
        Toast({
          message: '提交成功',
          duration: 0,
          overlay: true,
          forbidClick: true,
        });
      } else {
        this.isSubmiting = false;
      }
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    chooseFile(type) {
      this.$refs[type].chooseFile();
    },
    async afterRead(file, current) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/songguo/${date}/${t}-${this.randomString}-${current}${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        let url = await getOssFile(path);
        this[current] = url;
        this.form[current] = `/${path}`;

        // 保单ocr
        switch (current) {
          case 'compInsurance':
            this.handleOcrInsurance('compInsurance', `/${path}`);
            break;
          case 'commInsurance':
            this.handleOcrInsurance('commInsurance', `/${path}`);
            break;
          default:
            break;
        }
      }
      this.uploading.clear();
    },
    showAgreement(type) {
      this.agreementType = type;
      this.agreementVisible = true;
    },
    datetimeConfirm() {
      this.form[this.current] = this.$dayjs(this.currentDate).format(
        'YYYY-MM-DD'
      );
      this.datetimeCancel();
    },
    datetimeCancel() {
      this.current = '';
      this.currentDate = new Date();
      this.datetimeVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/vars';

.Vehicle {
  min-height: 100vh;
  background: #fff;
  padding-bottom: 0.58rem;
  box-sizing: border-box;

  .title {
    padding: 0.1rem 0 0.16rem;
    color: #999;
    font-size: 0.13rem;
    text-align: center;
  }

  .upload-wrapper {
    padding: 0 0.2rem;
    text-align: center;

    .item {
      position: relative;
      z-index: 10;
      display: inline-block;
      width: 1.8rem;
      height: 1.62rem;
      background: #f4f8fe;
      border-radius: 0.1rem;
      vertical-align: top;

      .van-uploader {
        z-index: -10;
        pointer-events: none;
      }

      &.item-front {
        margin-right: 0.07rem;
      }

      &.item-back {
        margin-left: 0.07rem;
      }

      .inner {
        img {
          display: inline-block;
          width: 1.5rem;
          height: 1rem;
          margin: 0.15rem;
          vertical-align: top;
        }

        p {
          height: 0.32rem;
          line-height: 0.32rem;
          background: $blue;
          border-radius: 0 0 0.1rem 0.1rem;
          color: #fff;
          text-align: center;

          @at-root .theme-orange & {
            background: $orange;
          }
        }
      }
    }
  }

  .upload-tips {
    margin: 0.2rem;

    p {
      b {
        display: block;
        margin-bottom: 0.1rem;
      }

      span {
        color: #ff5050;
      }
    }

    ul {
      display: flex;
      margin-top: 0.24rem;

      li {
        flex: 1;
        text-align: center;

        &:not(:last-child) {
          padding-right: 0.05rem;
        }

        img {
          width: 100%;
        }

        span {
          color: #919191;
          font-size: 0.12rem;
        }
      }
    }
  }

  .divider {
    margin: 0;
  }

  .result-wrapper {
    .btn-send {
      @at-root .theme-orange & {
        color: $orange;
        border-color: $orange;
      }

      &:disabled {
        color: #e1e4ea;
        border-color: #e1e4ea;
        opacity: 1;
      }
    }

    .btn {
      display: block;
      width: 3.8rem;
      height: 0.44rem;
      background-color: $blue;
      margin: 0.46rem auto 0;
      padding: 0;
      border: none;
      border-radius: 0.22rem;
      color: #fff;
      font-size: 0.17rem;
      // box-shadow: 0 0.03rem 0.1rem rgba($blue, 0.3);
      box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);

      @at-root .theme-orange & {
        background: $orange;
        border-color: $orange;
        // box-shadow: 0 0.03rem 0.1rem rgba($orange, 0.3);
      }

      &:disabled {
        background-color: $gray !important;
        // box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);
      }
    }
  }

  .tips {
    margin: 0.2rem 0;
    text-align: center;

    span {
      color: $blue;

      @at-root .theme-orange & {
        color: $orange;
      }
    }
  }

  .item-image {
    position: relative;
    z-index: 10;
    width: 0.8rem;
    height: 0.8rem;

    .van-uploader {
      z-index: -10;
      pointer-events: none;
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 0.8rem;
      height: 0.8rem;
      background-color: #f7f8fa;

      img {
        width: 100%;
        height: 100%;
      }

      .van-icon {
        color: #dcdee0;
        font-size: 0.24rem;
      }
    }
  }
}
</style>
