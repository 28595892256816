<template>
  <div class="Vehicle Sunshine">
    <div class="result-wrapper">
      <van-field v-model="form.name" label="姓名" readonly :border="false" />

      <van-field
        v-model="form.idNo"
        label="身份证号"
        readonly
        :border="false"
      />

      <van-field name="radio" label="1.业务线" :border="false">
        <template #input>
          <van-radio-group v-model="form.bizType" direction="horizontal">
            <van-radio name="单车">单车</van-radio>
            <van-radio name="电单">电单</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-model="form.addr"
        is-link
        readonly
        placeholder="请选择"
        :border="false"
        @click="areaShow = true"
      >
        <template #label>
          <div>
            2.所属城市
            <p>负责的作业区域所属省、市及区</p>
          </div>
        </template>
      </van-field>

      <van-field
        v-model="form.relatives"
        autosize
        placeholder="请输入"
        rows="1"
        type="textarea"
        :border="false"
      >
        <template #label>
          <div>
            3.您有无与同城市其他人员有亲属关系
            <p>
              <b>定义：</b
              >您本人与同城市的其他人员，包括且不限于其他运维、运维组长、城市经理、运管商工作人员存在亲属关系<br /><br />
              <b>填写标准：</b
              >没有则填<b>“无”</b>，有则写明<b>亲属姓名、与本人关系、关系人公司、部门及职务；</b>多个请换行填写
            </p>
          </div>
        </template>
      </van-field>

      <van-field
        v-model="form.competitor"
        autosize
        placeholder="请输入"
        rows="1"
        type="textarea"
        :border="false"
      >
        <template #label>
          <div>
            4.您或亲属拥有与青桔项目有竞争关系或业务关系的公司的权益（股份）
            <p>
              <b>定义：</b
              >您本人或亲属持有与青桔项目存在竞争关系（经营同类或相似产品或服务）或合作关系（项目的供应商、代理商、经销商、加盟商及其他各类合资/合作伙伴等直接合作方、通过中间环节与青桔项目形成业务关联的间接合作方及推荐、筛选或洽商阶段的潜在合作对象）的公司的任何权益（股份）（通过公开证券市场取得权益且仅持有低于该公司股本总额1%的权益的投资除外）<br /><br />
              <b>填写标准：</b
              >没有则填<b>“无”</b>，有则写明<b>关系人姓名、与本人关系、公司名称及持股比例；</b>多个请换行填写
            </p>
          </div>
        </template>
      </van-field>

      <van-field
        v-model="form.relationship"
        autosize
        placeholder="请输入"
        rows="1"
        type="textarea"
        :border="false"
      >
        <template #label>
          <div>
            5.您或亲属有无与青桔项目存在利益关联
            <p>
              <b>定义：</b
              >您本人或亲属与青桔项目有业务往来的个人或机构（直接、简介合作方及潜在合作对象）提供贷（借）款、为其担保贷（借）款，从其获得贷（借）款或在其协助下获得贷（借）款（与金融机构的正常借贷除外），或非正常业务的其他经济往来；您本人或亲属（包括其实际控制的机构）与青桔项目形成业务往来关系，包括但不限于购买或销售商品、购买或销售除商品以外的其他资产、提供或接受管理服务、劳务服务或代理服务、租赁资产或设备、提供资金、共同研究与开发项目、签署许可协议、赠与或达成任何交易，促使运维本人或亲属成为青桔项目的合作方或潜在合作方或达成其他任何交易关系。<br /><br />
              <b>填写标准：</b
              >没有则填<b>“无”</b>，有则写明<b>关系人姓名、与本人关系、公司名称及合作内容；</b>多个请换行填写
            </p>
          </div>
        </template>
      </van-field>

      <van-field
        v-model="form.employmentRelation"
        autosize
        placeholder="请输入"
        rows="1"
        type="textarea"
        :border="false"
      >
        <template #label>
          <div>
            6.您有无与青桔项目竞争方或合作方之间存在聘任关系或其他任何关联活动
            <p>
              <b>定义：</b
              >您本人受聘与青桔项目竞争方或合作方，或与青桔项目的竞争方或合作方发生任何形式的业务关联或其他利益关联（包括以咨询、顾问或其他类似身份从事的活动），以及从事其他损害或有可能损害青桔项目利益的活动（如成为竞争方的供应商或代理商等）；您的亲属受聘与青桔项目的竞争方，或受聘与合作方并担任管理岗位职务（董事、监事、总经理或副总经理等）或从事敏感岗位工作（如销售、采购、产品技术、战略、财务、策略、运营分析或与青桔项目对接等类型工作）；运维本人或亲属销售任何对青桔项目现有或潜在商业活动构成竞争的产品，或提供任何对青桔项目现有或潜在商业活动构成竞争的服务。<br /><br />
              <b>填写标准：</b
              >没有则填<b>“无”</b>，有则写明<b>关系人姓名、与本人关系、公司名称及从事工作；</b>多个请换行填写
            </p>
          </div>
        </template>
      </van-field>

      <van-field
        v-model="form.partTimeJob"
        autosize
        placeholder="请输入"
        rows="1"
        type="textarea"
        :border="false"
      >
        <template #label>
          <div>
            7.您有无接受青桔项目利益相关方礼品馈及其他兼职
            <p>
              <b>定义：</b
              >您接受利益相关方礼品馈赠，需上报给青桔项目管理组处置；工作时间为外部企业、个人提供服务的兼职活动。<br /><br />
              <b>填写标准：</b
              >没有则填<b>“无”</b>，有则写明<b>关系人姓名、与本人关系、相关公司名称及内容；</b>多个请换行填写
            </p>
          </div>
        </template>
      </van-field>

      <van-field v-model="form.others" placeholder="请输入" :border="false">
        <template #label>
          <div>
            8.您有无其他事项补充
            <p>
              <b>定义：</b>与问卷标题描述的其他情况。<br /><br />
              <b>填写标准：</b>没有则填<b>“无”</b>，有则写明情况。
            </p>
          </div>
        </template>
      </van-field>

      <div class="tips">
        <span @click="showAgreement">《运维阳光申报问卷说明》</span>
      </div>

      <div class="check">
        <van-checkbox v-model="checked"
          >个人承诺：本人已如实填报上述信息，如未诚实提报，本人愿意承担相应责任</van-checkbox
        >
      </div>

      <button
        v-if="!isSubmiting"
        class="btn"
        :disabled="isDisabled || !checked"
        @click="handleSubmit"
      >
        提交
      </button>
      <button v-else class="btn" disabled>提交中</button>
    </div>

    <van-dialog
      v-model="agreementVisible"
      confirmButtonText="我已阅读并同意以上条款"
      width="3.6rem"
    >
      <Agreement :agreementVisible="agreementVisible" />
    </van-dialog>

    <van-popup v-model="areaShow" round position="bottom">
      <van-area
        title="请选择所属城市"
        :area-list="areaList"
        @confirm="onAreaConfirm"
        @cancel="onAreaCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import {
  ActionSheet,
  Area,
  Button,
  Cascader,
  Checkbox,
  Dialog,
  Divider,
  Field,
  Icon,
  Notify,
  Picker,
  Popup,
  RadioGroup,
  Radio,
  Switch,
  Toast,
  Uploader,
} from 'vant';
import { areaList } from '@vant/area-data';
import Agreement from '../components/AgreementSunshine.vue';

Vue.use(ActionSheet)
  .use(Area)
  .use(Button)
  .use(Cascader)
  .use(Checkbox)
  .use(Dialog)
  .use(Divider)
  .use(Field)
  .use(Icon)
  .use(Notify)
  .use(Picker)
  .use(Popup)
  .use(RadioGroup)
  .use(Radio)
  .use(Switch)
  .use(Uploader);

export default {
  name: 'Sunshine',
  components: {
    Agreement,
  },
  data() {
    return {
      checked: false,
      accountId: null,
      areaList,
      areaShow: false,
      form: {
        name: '',
        idNo: '',
        bizType: '',
        addr: '',
        relatives: '',
        competitor: '',
        relationship: '',
        employmentRelation: '',
        partTimeJob: '',
        others: '',
      },
      uploading: null,
      agreementVisible: true,
      isSubmiting: false,
    };
  },
  computed: {
    isDisabled() {
      return (
        !this.form.name ||
        !this.form.idNo ||
        !this.form.bizType ||
        !this.form.addr ||
        !this.form.relatives ||
        !this.form.competitor ||
        !this.form.relationship ||
        !this.form.employmentRelation ||
        !this.form.partTimeJob ||
        !this.form.others
      );
    },
    ...mapState({
      taskId: (state) => state.taskId,
      color: (state) => state.color,
    }),
  },
  created() {
    let { accountId, name, idNo } = this.$route.query;
    this.accountId = accountId;
    this.form.name = name;
    this.form.idNo = idNo;
  },
  methods: {
    handleSubmit() {
      let postData = { ...this.form, taskId: this.taskId };
      Dialog.confirm({
        title: '个人承诺',
        message:
          '个人承诺本人已如实填报上述信息，如未诚实提报，本人愿意承担相应责任',
      })
        .then(() => {
          this.handleAdd(postData);
        })
        .catch(() => {
          // on cancel
        });
    },
    async handleAdd(postData) {
      this.isSubmiting = true;
      const data = await this.$axios.post(
        '/tax/wechatController/addQJQuestion',
        postData
      );
      if (data.success) {
        this.getUrl(data.data);
        this.uploading = Toast.loading({
          message: '合同生成中',
          forbidClick: true,
        });
      } else {
        this.isSubmiting = false;
      }
    },
    async getUrl() {
      const data = await this.$axios.post('/tax/wechatController/doEleSign', {
        accountId: this.accountId,
        taskId: this.taskId,
      });
      if (data.success) {
        if (data.data.shortUrl) {
          window.location.href = data.data.shortUrl;
        } else {
          this.uploading.clear();
          this.$router.replace('result');
        }
      }
      this.isSubmiting = false;
    },
    showAgreement() {
      this.agreementVisible = true;
    },
    onAreaConfirm(arr) {
      console.log(arr);
      this.form.addr = `${arr[0].name}/${arr[1].name}/${arr[2].name}`;
      this.areaShow = false;
    },
    onAreaCancel() {
      this.areaShow = false;
    },
  },
};
</script>

<style lang="scss">
// @import '../styles/vars';
.Sunshine {
  .icon {
    margin-left: 0.03rem;
  }

  .van-cell {
    display: block;

    .van-field__label {
      width: auto;
      margin-right: 0;
      color: #000;
      text-align: justify;

      p {
        color: #8b8b8b;
      }
    }

    .van-cell__right-icon {
      position: absolute;
      right: 0.16rem;
      bottom: 0.1rem;
    }

    .van-field__control {
      box-shadow: 0 0 0 1px #323233;

      &.van-field__control--custom {
        box-shadow: none;
      }
    }
  }

  .check {
    padding: 0 0.16rem;
  }
}
</style>
