<template>
  <van-popup :value="bgCheckVisible" :close-on-click-overlay="false">
    <div class="BackgroundCheck">
      <div class="form">
        <van-field v-model="form.name" label="姓名" placeholder="请输入" />
        <van-field v-model="form.idNo" label="身份证号" placeholder="请输入" />
        <van-field
          v-model="form.mobile"
          label="手机号码"
          placeholder="请输入"
        />
        <van-field
          v-model="form.smsCode"
          center
          clearable
          label="短信验证码"
          maxlength="4"
          placeholder="请输入"
        >
          <template #button>
            <van-button
              v-if="!counting"
              class="btn-send"
              size="small"
              type="info"
              plain
              :disabled="form.mobile.length < 11"
              @click="getCode"
              >获取验证码</van-button
            >
            <span v-if="counting" class="count-down"
              >{{ seconds }}s后可重发</span
            >
          </template>
        </van-field>

        <button
          class="btn"
          :disabled="disabled || submiting"
          :style="{ background: '#' + $store.state.color }"
          @click="handleSubmit"
        >
          开始背调
        </button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';

Vue.use(Dialog).use(Toast);

export default {
  name: 'BackgroundCheck',
  // props: ['visible'],
  data() {
    return {
      form: {
        name: '',
        idNo: '',
        mobile: '',
        smsCode: '',
        smsCodeId: '',
      },
      seconds: 60,
      counting: false,
      submiting: false,
    };
  },
  computed: {
    ...mapState({
      platformId: (state) => state.platformId,
      bgCheckVisible: (state) => (state.bgCheckVisible ? true : false),
    }),
    disabled() {
      return (
        !this.form.name ||
        !this.form.idNo ||
        !this.form.mobile ||
        !this.form.smsCode
      );
    },
  },
  created() {},
  methods: {
    async getCode() {
      let reg = /^1\d{10}$/;
      if (!reg.test(this.form.mobile)) {
        Toast({
          message: '请输入正确格式的手机号码',
          position: 'bottom',
        });
        return;
      }
      this.counting = true;
      this.seconds = 60;
      this.form.smsCodeId = '';
      let t1 = setInterval(() => {
        if (this.seconds < 1) {
          this.counting = false;
          clearInterval(t1);
        } else {
          this.seconds--;
        }
      }, 1000);
      const data = await this.$axios.post('/system/wechatController/sendMsg', {
        phone: this.form.mobile,
        type: 11,
      });
      if (data.success) {
      }
    },
    async handleSubmit() {
      this.submiting = true;
      const data = await this.$axios.post('/tax/wechat/user/backgroundCheck', {
        name: this.form.name.trim(),
        idNo: this.form.idNo.trim(),
        mobile: this.form.mobile,
        smsCode: this.form.smsCode,
      });
      if (data.success) {
        const { result } = data.data;
        if (result) {
          this.$store.commit('setBgCheckVisible', 0);
        } else {
          Dialog.alert({
            message: '背调短信已发出，请完成背调后再签约',
          }).then(() => {});
        }
      }
      this.submiting = false;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/vars';

.BackgroundCheck {
  width: 4.14rem;
  height: 100vh;
  background: #fff;

  .form {
    padding-top: 0.12rem;

    .btn-send {
      @at-root .theme-orange & {
        color: $orange;
        border-color: $orange;
      }

      &:disabled {
        color: #e1e4ea;
        border-color: #e1e4ea;
        opacity: 1;
      }
    }

    .btn {
      display: block;
      width: 3.8rem;
      height: 0.44rem;
      background-color: $blue;
      margin: 0.46rem auto 0;
      padding: 0;
      border: none;
      border-radius: 0.22rem;
      color: #fff;
      font-size: 0.17rem;
      box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);

      @at-root .theme-orange & {
        background: $orange;
        border-color: $orange;
      }

      &:disabled {
        background-color: $gray !important;
      }
    }
  }
}
</style>
